
import { defineComponent, ref, onMounted } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import AddCallOutRecordsModal from "@/views/task-management/call-out-records/AddCallOutRecordsModal.vue";
import FilterDropdwon from "@/views/task-management/call-out-records/FilterDropdown.vue";
import CallOutRecordsImportModal from "@/views/task-management/call-out-records/ImportModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiTaskManagement } from "@/core/api";
import moment from "moment";
import _ from "lodash";
import fileDownload from "js-file-download";
import { useI18n } from "vue-i18n";
import {
  formatDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";

interface CallOutRecords {
  id: number;
  name: string;
  description: string;
}

interface SortOption {
  field: string;
  direction: string;
}

interface FilterOption {
  field: string;
  value: string;
  from?: string;
  to?: number;
  condition?: string;
}

export default defineComponent({
  name: "call-out-records",
  components: {
    MBDatatable,
    FilterDropdwon,
    AddCallOutRecordsModal,
    CallOutRecordsImportModal,
  },
  setup() {
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<CallOutRecords>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const disabledExport = ref<boolean>(true);
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([]);
    const { t } = useI18n();
    const tableHeader = ref([
      // {
      //   name: "ID",
      //   key: "id",
      //   sortable: true,
      // },
      {
        name: t("callOutRecords.contact"),
        key: "concat",
        sortable: false,
      },
      {
        name: t("callOutRecords.taskOwner"),
        key: "task_owner",
        sortable: false,
      },
      {
        name: t("callOutRecords.dueDate"),
        key: "due_date",
        sortable: false,
      },
      {
        name: t("callOutRecords.actionDate"),
        key: "action_date",
        sortable: false,
      },
      {
        name: t("callOutRecords.importance"),
        key: "importance",
        sortable: false,
      },
      {
        name: t("callOutRecords.comment"),
        key: "comment",
        sortable: false,
      },
      {
        name: "", // t("common.actions")
        key: "actions",
        sortable: false,
      },
    ]);

    const improtanceClass = (val) => {
      const status = new Map([
        [1, t("callOutRecords.important")],
        [2, t("callOutRecords.conventional")],
        [3, t("callOutRecords.invalid")],
      ]);
      if (val == 1) {
        return (
          '<span class="badge badge-light-info">' + status.get(val) + "</span>"
        );
      } else if (val == 2) {
        return (
          '<span class="badge badge-light-success">' +
          status.get(val) +
          "</span>"
        );
      } else if (val == 3) {
        return (
          '<span class="badge badge-light-warning">' +
          status.get(val) +
          "</span>"
        );
      } else {
        return "";
      }
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getCallOutRecordsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    });

    const fileExport = () => {
      disabledExport.value = true;
      ApiTaskManagement.exportCallOutRecords({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        download_data_type: "stream",
      })
        .then((data) => {
          disabledExport.value = false;
          const disposition = data.headers["content-disposition"];
          fileDownload(data.data, disposition.split("filename=")[1]);
        })
        .catch((error) => {
          disabledExport.value = false;
          console.log(error);
        });
    };

    const getCallOutRecordsList = (
      page: number,
      pageSize: number,
      search?: string,
      sortOptions?: Array<SortOption>,
      filterOptions?: Array<FilterOption>
    ) => {
      loading.value = true;
      ApiTaskManagement.getCallOutRecordsList({
        page: page,
        page_size: pageSize,
        search_value: search,
        sort_orders: sortOptions,
        filter_group: filterOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          disabledExport.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] !== "") {
          filterArr.push({
            field: item,
            value: val[item],
            condition: "eq",
          });
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getCallOutRecordsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getCallOutRecordsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getCallOutRecordsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getCallOutRecordsList(
        page,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getCallOutRecordsList(
        currentPage.value,
        pagesize,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const debounceSearch = _.debounce(getCallOutRecordsList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const updateList = () => {
      getCallOutRecordsList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    return {
      t,
      formatDate,
      formatDateTime,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      disabledExport,
      fileExport,
      getCallOutRecordsList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      updateList,
      improtanceClass,
    };
  },
});
