
import { ApiBase, ApiChanel } from "@/core/api";
import { defineComponent, onBeforeMount, ref } from "vue";
import _ from "lodash";
import { useI18n } from "vue-i18n";

interface Filter {
  task_owner: string;
  contact: string;
  channel: string;
  Importance: string;
}

export default defineComponent({
  name: "call-out-records-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const data = ref<Filter>({
      task_owner: "",
      contact: "",
      channel: "",
      Importance: "",
    });
    const taskOwnerOptions = ref([]);
    const taskOwnerLoading = ref<boolean>(false);
    const contactOptions = ref([]);
    const contactLoading = ref<boolean>(false);
    const { t } = useI18n();
    const switchUserType = (type: number, status: boolean) => {
      switch (type) {
        case 0:
          status
            ? (taskOwnerLoading.value = true)
            : (taskOwnerLoading.value = false);
          break;
        default:
          break;
      }
    };

    const switchUserValue = (type: number, data: any) => {
      switch (type) {
        case 0:
          taskOwnerOptions.value = data;
          break;
        default:
          break;
      }
    };

    const getUserSourceData = async (
      type: number,
      value?: string,
      id?: string
    ) => {
      switchUserType(type, true);
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };

      const { data } = await ApiBase.getUserSourceData({
        ...params,
        status: 0,
      });
      switchUserType(type, false);
      if (data.code == 0) {
        switchUserValue(type, data.data);
      }
    };

    const debounceUserSearch = _.debounce(getUserSourceData, 1000);
    const searchTaskOwnerItems = (query) => {
      debounceUserSearch(0, query);
    };

    const switchAccountType = (type: number, status: boolean) => {
      switch (type) {
        case 0:
          status
            ? (contactLoading.value = true)
            : (contactLoading.value = false);
          break;
        default:
          break;
      }
    };

    const switchAccountValue = (type: number, data: any) => {
      switch (type) {
        case 0:
          contactOptions.value = data;
          break;
        default:
          break;
      }
    };

    const getAccountSourceData = async (
      type: number,
      value?: string,
      id?: string
    ) => {
      switchAccountType(type, true);
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };
      const { data } = await ApiBase.getAccountSourceData(params);
      switchAccountType(type, false);
      if (data.code == 0) {
        switchAccountValue(type, data.data);
      }
    };
    const debounceAccountSearch = _.debounce(getAccountSourceData, 1000);
    const searchContactItems = (query) => {
      debounceAccountSearch(0, query);
    };

    const submit = () => {
      emit("submit-filter", data.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    return {
      data,
      formRef,
      taskOwnerOptions,
      taskOwnerLoading,
      contactOptions,
      contactLoading,
      t,
      searchTaskOwnerItems,
      searchContactItems,
      submit,
      handleReset,
    };
  },
});
