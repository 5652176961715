
import { defineComponent, ref } from "vue";
import { Upload } from "@element-plus/icons-vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import { ApiTaskManagement } from "@/core/api";
import JwtService from "@/core/services/JwtService";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "call_out_record_import-modal",
  emits: ["update-list"],
  components: {},
  setup(props, { emit }) {
    const callOutRecordImportModalRef = ref<null | HTMLElement>(null);
    const { showServerErrorMsg } = mixin();

    const { t } = useI18n();

    const uploadUrl = ApiTaskManagement.uploadCallOutRecordsExcel();
    const uploadHeaders = ref({
      Authorization: `Bearer ${JwtService.getToken()}`,
    });

    const handleUploadSuccess = (res, file) => {
      console.log(res);
      console.log(file);
      if (res.code == 0) {
        Swal.fire({
          text: t("callOutRecords.importSuccess"),
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: t("common.okGotIt"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          hideModal(callOutRecordImportModalRef.value);
          emit("update-list");
        });
      } else {
        showServerErrorMsg(res);
      }
    };

    return {
      t,
      handleUploadSuccess,
      uploadUrl,
      uploadHeaders,
      callOutRecordImportModalRef,
      Upload,
    };
  },
});
